<template>
  <div class="box">
    <SATMenu active="3" />
    <div class="container">
      <h1 class="sat_left">
        <p>SAT</p>
        <span>® </span>
        <p>完整考試</p>
        <router-link
          :to="{ name: 'SatComposePracticeDetail' }"
          class="create-compose-practice"
        >
          <i class="fa fa-plus"></i>
        </router-link>
      </h1>
      <hr />
      <div v-if="practices">
        <div class="text-center" style="margin:15px 0">
          <el-pagination
            background
            @current-change="setPage"
            :page-size="pageSize"
            :current-page="page"
            :total="resultsTotal"
            :pager-count="9"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
        <div class="row">
          <div
            class="col-sm-6"
            v-for="practice in practices"
            :key="practice.id"
          >
            <PracticeCard
              :title="practice.exam.title"
              :router="{
                name: 'SatComposePracticeDetail',
                query: { id: practice.id }
              }"
            />
          </div>
        </div>
        <div class="text-center" style="margin:15px 0">
          <el-pagination
            background
            @current-change="setPage"
            :page-size="pageSize"
            :current-page="page"
            :total="resultsTotal"
            :pager-count="9"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import SATMenu from "@/views/satList/components/SATMenu.vue";
import PracticeCard from "@/views/satComposePractice/components/ViewQuestionCard";
import SAT from "@/apis/sat.js";

export default {
  metaInfo() {
    return {
      title: "SAT Practices - " + this.CompanyName
    };
  },

  components: {
    SATMenu,
    PracticeCard
  },

  mixins: [],

  props: [],
  data() {
    return {
      activeName: "composePractices",
      practices: null,
      resultsTotal: 0,
      pageSize: 0,
      subjects: [],
      questions: []
      // composePractices: null
    };
  },
  computed: {
    isLogin() {
      return this.token !== "";
    },
    testType() {
      let testType = "all";
      if (this.$route.query.testType) {
        testType = this.$route.query.testType;
      }
      return testType;
    },
    subjectId() {
      let subjectId = null;
      if (this.$route.query.subject_id) {
        subjectId = this.$route.query.subject_id;
      }
      return subjectId;
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    }
  },
  watch: {},

  async mounted() {
    // const subjects = await SAT.getSubjects();
    // this.subjects = subjects.sat_subjects;
    // let res = null;
    // if (this.testType === "all") {
    //   res = await SAT.getSatQuestions({
    //     page: this.page
    //   });
    // } else {
    //   res = await SAT.getSatSubjectQuestions(this.subjectId, {
    //     page: this.page
    //   });
    //   this.questions = res.sat_questions;
    // }
    this.getPractices();
  },

  methods: {
    async getPractices() {
      const res = await SAT.getComposePractices({
        page: this.page
      });
      this.practices = res.compose_practice.data;
      this.resultsTotal = res.compose_practice.total;
      this.pageSize = res.compose_practice.per_page;
    },
    setPage(page) {
      this.routerPush({
        name: "SATList",
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    }
  }
};
</script>

<style scoped>
.box .container {
  padding-top: 0;
  /* padding: 0 40px 20px 40px; */
  margin-top: 40px;
  margin-bottom: 40px;
}

.sat_left {
  margin: 20px 0;
  display: flex;
  height: 50px;
  text-decoration: none;
  align-items: center;
}
.create-compose-practice {
  margin-left: 10px;
  font-size: 60%;
  line-height: 50px;
}

.sat_left > span {
  font-weight: 700;
  font-size: 20px;
}
.sat_left > p {
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 0px;
}
</style>
